import React, { Component } from 'react';
import { History, Location, LocationDescriptor } from 'history';
import { Link } from 'react-router-dom'
import { API, Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { withStyles, Theme } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import HistoryIcon from '@material-ui/icons/History';

import TablePaginationActions from '../TablePaginationActions';


const styles = (theme: Theme) => ({
    root: {
        maxWidth: '100%',
        //margin: 6,
        'margin-top': 6 * 3,
        //background: 'lightblue',
    },
    logoPre: {
        marginLeft: 6,
        marginRight: 3,
        color: '#0077B4',
        fontWeight: 'bold',
        fontFamily: 'Calibri',
        fontStyle: 'italic',
        fontSize: 25,
    },
    logoPost: {
        margin: 0,
        color: '#C8D400',
        fontWeight: 'bold',
        fontFamily: 'Calibri',
        fontStyle: 'italic',
        fontSize: 34,
    },
    heading: {
        margin: 6,
    },
    tablePaper: {
        //width: '98%',
        maxWidth: '100%',
        margin: 6,
        overflowX: 'auto',
    },
    table: {
        minWidth: 300,
    },
    greenButton: {
        margin: '1%',
        background: '#C8D400',
        width: '95%',
        textTransform: 'none',
        border: 'none'
    },
    blueButton: {
        margin: 6,
        background: '#0077B4'
    },
    blueButtonWhiteLabel: {
        margin: '1%',
        width: '95%',
        background: '#0077B4',
        color: '#ffffff',
        textTransform: 'none',
        border: 'none',
    },
    statusLabel: {
        background: 'transparent',
        color: 'black',
        border: 'none',
        outline: 'none',
        textTransform: 'none',
        paddingLeft: '0',
        margin: '0',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start'
    },
    tableCell: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 6,
        paddingRight: 6,
    },
    tableCellStatus: {
        width: '50%'
    },
    leftIcon: {
        marginRight: 6,
    },
    rightIcon: {
        marginLeft: 6,
    },
    iconSmall: {
        fontSize: 20,
    },
    toggledOnIcon: {
        color: '#ebd300',
        //display: 'block',
        //marginLeft: 'auto',
        //marginRight: 'auto',
    },
    toggledOffIcon: {
        color: '#eb0000',
        //display: 'block',
        //marginLeft: 'auto',
        //marginRight: 'auto',
    },
    spinner: {
        margin: 100,
    }
});

interface IBaustrommeldung {
    id: string,
    typ: string,
    auftragsnummer: string,
    aob: string,
    zaehlernummer: string,
    aobAdresse: string,
    status: string,
    searchParam: string
}

interface ButtonLinkProps extends ButtonProps {
    to: LocationDescriptor
}

interface TableCellLinkProps extends ButtonProps {
    classes: any,
    to: LocationDescriptor
}

const ButtonLink = ({ to, ...props }: ButtonLinkProps) => {
    // @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877
    return <Button component={Link} to={to} {...props} />;
};

const TableCellLink = ({ to, classes, ...props }: TableCellLinkProps) => {
    // @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877
    const inputRef = React.useRef(null);
    return (
        <TableCell style={{ cursor: 'pointer' }}
            className={classes.tableCell}
            onClick={() => {
                if (inputRef) {
                    // @ts-ignore
                    inputRef.current.click();
                }
            }
            }>
            <Link ref={inputRef} to={to}>
                <span style={{ display: 'block', textDecoration: 'none', color: 'black' }} {...props} />
            </Link>
        </TableCell>
    );
};

const translateStatus = (status: string) => {
    if (status === "In Anlage durch Installateur") {
        return "Zwischengespeichert";
    }
    if (status === "Abgeschickt an EWE") {
        return "Eingereicht";
    }
    return status;
};

const actionsStyles = (theme: Theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: 6 * 2.5,
    },
});

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
    TablePaginationActions,
);

interface IAdminSearchProps {
    history: History,
    location: Location,
    classes: any
}

interface IAdminSearchState {
    baustrommeldungen: IBaustrommeldung[],
    benutzer: string,
    gruppe: string,
    addButtonVisible: boolean,
    isLoading: boolean,
    rowsPerPage: number,
    aob: string,
    zaehlernummer: string,
    page: number
}

class AdminSearch extends Component<IAdminSearchProps, IAdminSearchState> {

    static propTypes = {};

    constructor(props: IAdminSearchProps) {
        super(props);

        this.handleChangeSearchField = this.handleChangeSearchField.bind(this);
        this.handleButtonSearchClick = this.handleButtonSearchClick.bind(this);


        this.state = {
            baustrommeldungen: [],
            benutzer: '',
            gruppe: '',
            addButtonVisible: false,
            isLoading: false,
            rowsPerPage: 10,
            aob: '',
            zaehlernummer: '',
            page: 0
        }
    }

    componentDidMount() {
        this.getUserId().then(id => this.setState({ benutzer: id }));
        this.getGroup().then(group => this.setState({ gruppe: group }));
    }

    async getData(aob: string, zaehlernummer: string) {
        const user = await Auth.currentAuthenticatedUser();
        const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
        // @ts-ignore
        const jwt = (await Auth.currentSession()).idToken.jwtToken;
        let endpoint = '/baustrom/query/overall?aob=' + aob;
        if (!aob || aob.length === 0) {
            endpoint = '/baustrom/query/overall?zaehlernummer=' + zaehlernummer;
        }

        //console.log(jwt);

        let data = await API.get('ApiGatewayRestApi', endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            }
        });
        //if (group === "verantwortliche_Fachkraft" || group === "Sachbearbeiter") {
        //return data.filter(row => row['benutzer'] === userId && row['auftragsstatus'] === 'In Anlage durch Installateur');
        //    this.setState({ addButtonVisible: true });
        //} else {
        //    this.setState({ addButtonVisible: false });
        //}

        if (!(groups.includes("EWE-Mitarbeiter") || groups.includes("EWE-Admin"))) {
            data = [];
        }

        return data;
    }

    async getUserId() {
        try {
            const user = await Auth.currentAuthenticatedUser();
            return user.attributes.sub;
        } catch (e) {
            console.log('get user id failed', e);
            return null;
        }
    }

    async getGroup() {
        try {
            const user = await Auth.currentAuthenticatedUser();
            return user.signInUserSession.accessToken.payload["cognito:groups"][0];
        } catch (e) {
            console.log('get user group failed', e);
            return null;
        }
    }


    handleButtonSearchClick = async (event: any) => {
        this.setState({ isLoading: true });
        this.getData(this.state.aob, this.state.zaehlernummer).then(data => this.setState({
            baustrommeldungen: data,
            isLoading: false
        }));
    }

    handleChangeSearchField(event: any) {
        //console.log(event.target.name);
        if (event.target.name === 'aob') {
            this.setState({
                aob: event.target.value,
                page: 0
            });
        } else {
            this.setState({
                zaehlernummer: event.target.value,
                page: 0
            });
        }

    }

    handleChangePage = (event: any, page: number) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event: any) => {
        this.setState({ page: 0, rowsPerPage: Number(event.target.value) });
    };

    render() {
        const { classes } = this.props;

        //const linkTo = "/zaehlerAusbauFormNewPrefilled/";


        const rows: any[] = [];
        let page = 0;
        // @ts-ignore
        if (typeof this.props.location !== 'undefined' && typeof this.props.location.state !== 'undefined' && typeof this.props.location.state.page !== 'undefined') {
            // @ts-ignore
            page = this.props.location.state.page;
        }

        let baustrommeldungen: IBaustrommeldung[] = this.state.baustrommeldungen;
        //fertigmeldungen.sort(function(a, b){return b.createdAt - a.createdAt});
        /*
        if (!this.state.showAllFMO) {
            baustrommeldungen = baustrommeldungen.filter(row => {
                return (this.props.gruppe === "verantwortliche_Fachkraft" || this.props.gruppe === "Sachbearbeiter") ? row['status'] === 'Zur Bearbeitung bei VF' : row['status'] !== 'Abgeschlossen durch EWE';
            });
        }
        
        if (this.state.filter && this.state.filter !== '') {
            baustrommeldungen = baustrommeldungen.filter(row => {
                return row['searchParam'].includes(this.state.filter.toLowerCase());
            });
        }
        */

        const BaustromIcon = (props: any) => {
            let returnVal = <HistoryIcon style={{ color: 'orange' }} fontSize="small" className={classes.rightIcon} />;
            switch (props.status) {
                case "Abgeschickt an EWE":
                    returnVal = <MailOutlineIcon style={{ color: 'darkblue' }} fontSize="small" className={classes.rightIcon} />;
                    break;
                case "Angenommen von EWE":
                    returnVal = <CallSplitIcon style={{ color: 'darkblue' }} fontSize="small" className={classes.rightIcon} />;
                    break;
                case "Abgeschlossen durch EWE":
                    returnVal = <CheckIcon style={{ color: 'green' }} fontSize="small" className={classes.rightIcon} />;
                    break;
                case "Verarbeitung nicht erfolgreich":
                    returnVal = <CloseIcon style={{ color: 'red' }} fontSize="small" className={classes.rightIcon} />;
                    break;
                case "Zur Bearbeitung an BZM":
                    returnVal = <CloseIcon style={{ color: 'red' }} fontSize="small" className={classes.rightIcon} />;
                    break;
                case "Vorprozess erfolgreich bearbeitet":
                    returnVal = <DoubleArrowIcon style={{ color: 'darkblue' }} fontSize="small" className={classes.rightIcon} />;
                    break;
            }
            return returnVal;
        };

        const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, baustrommeldungen.length - page * this.state.rowsPerPage);

        baustrommeldungen.forEach((baustrommeldung) => {
            const linkTo = baustrommeldung.typ === 'Zählereinbau' ? `/zaehlerEinbauFormOpen/${baustrommeldung.id}` : `/zaehlerAusbauFormOpen/${baustrommeldung.id}`;
            rows.push(
                <TableRow
                    key={baustrommeldung.id}
                >
                    <TableCellLink to={{
                        pathname: linkTo,
                        state: {
                            page: page,
                            id: baustrommeldung.id
                        }
                    }} classes={classes}>
                        {
                            baustrommeldung.typ === 'Zählereinbau' ?
                                <CallMadeIcon style={{ color: 'green' }} fontSize="small" className={classes.rightIcon} /> :
                                <CallReceivedIcon color="primary" fontSize="small" className={classes.rightIcon} />
                        }
                        <Hidden smDown>&nbsp;{baustrommeldung.typ}</Hidden>
                    </TableCellLink>
                    <Hidden smDown>
                        <TableCellLink to={{
                            pathname: linkTo,
                            state: {
                                page: page,
                                id: baustrommeldung.id
                            }
                        }} classes={classes}>{baustrommeldung.aob || ""}</TableCellLink>
                    </Hidden>
                    <TableCellLink to={{
                        pathname: linkTo,
                        state: {
                            page: page,
                            id: baustrommeldung.id
                        }
                    }} classes={classes}>{baustrommeldung.auftragsnummer}</TableCellLink>
                    <TableCellLink to={{
                        pathname: linkTo,
                        state: {
                            page: page,
                            id: baustrommeldung.id
                        }
                    }} classes={classes}>{baustrommeldung.aobAdresse}</TableCellLink>
                    <TableCell align="left" className={classes.tableCell}>
                        {
                            baustrommeldung.typ === 'Zählereinbau' ?
                                <ButtonLink variant="outlined" to={{
                                    pathname: `/stateHistory/${baustrommeldung.id}`,
                                    state: {
                                        filter: "",
                                        page: page,
                                        showAllFMO: false
                                    }
                                }} className={classes.statusLabel}>
                                    <BaustromIcon status={baustrommeldung.status} />&nbsp;&nbsp;
                                    {translateStatus(baustrommeldung.status)}
                                </ButtonLink>
                                :
                                <ButtonLink variant="outlined" to={{
                                    pathname: `/stateHistoryZaehlerausbau/${baustrommeldung.id}`,
                                    state: {
                                        filter: "",
                                        page: page,
                                        showAllFMO: false
                                    }
                                }} className={classes.statusLabel}>
                                    <BaustromIcon status={baustrommeldung.status} />&nbsp;&nbsp;
                                    {translateStatus(baustrommeldung.status)}
                                </ButtonLink>
                        }
                    </TableCell>
                </TableRow>
            );
        });




        return (
            <Grid container spacing={2} style={{ maxWidth: '100%' }} alignItems="center" justify="center" >
                <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center" justify="center" >
                        <Grid item xs={12} lg={8}>
                            <Grid container spacing={2} style={{ background: '#C8D400' }} alignItems="center" justify="center" >
                                <Grid item xs={12} lg={8}>
                                    <Paper className={classes.tablePaper} style={{ maxWidth: '100%' }}>
                                        <Typography variant="h5" component="h3" className={classes.heading}>
                                            Baustromrückmeldungen ({baustrommeldungen.length})
                                        </Typography>
                                        <Grid container style={{ maxWidth: '100%' }}>
                                            <Grid item xs={12} md={6}>
                                                <TextField id="standard-basic"
                                                    inputProps={{
                                                        autoComplete: 'off'
                                                    }}
                                                    label="AOB"
                                                    name="aob"
                                                    onChange={(e) => {
                                                        // @ts-ignore
                                                        this.handleChangeSearchField(e);
                                                    }}
                                                    style={{ marginLeft: '2em' }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField id="standard-basic"
                                                    inputProps={{
                                                        autoComplete: 'off'
                                                    }}
                                                    label="Zählernummer"
                                                    name="zaehlernummer"
                                                    onChange={(e) => {
                                                        // @ts-ignore
                                                        this.handleChangeSearchField(e);
                                                    }}
                                                    style={{ marginLeft: '2em' }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3}>
                                                <Button variant="outlined" onClick={this.handleButtonSearchClick} className={classes.greenButton}>Suchen</Button>
                                            </Grid>
                                        </Grid>
                                        <div className={classes.tableWrapper}>
                                            <Table className={classes.table}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left" className={classes.tableCell}>Art</TableCell>
                                                        <Hidden smDown>
                                                            <TableCell align="left" className={classes.tableCell}>AOB</TableCell>
                                                        </Hidden>
                                                        <TableCell align="left" className={classes.tableCell}>Auftragsnr.</TableCell>
                                                        <TableCell align="left" className={classes.tableCell}>AOB Adresse</TableCell>
                                                        <TableCell align="left" className={classes.tableCell}>Status</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {this.state.isLoading ? <div className={classes.spinner}><CircularProgress /></div> :
                                                    <TableBody>
                                                        {
                                                            // @ts-ignore
                                                            rows.slice(page * this.state.rowsPerPage, page * this.state.rowsPerPage + this.state.rowsPerPage)
                                                        }
                                                        {
                                                            emptyRows > 0 && (
                                                                <TableRow style={{ height: 48 * emptyRows }}>
                                                                    <TableCell colSpan={6} />
                                                                </TableRow>
                                                            )
                                                        }
                                                    </TableBody>}
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[5, 10, 25]}
                                                            labelRowsPerPage="Zeilen pro Seite"
                                                            colSpan={6}
                                                            count={rows.length}
                                                            rowsPerPage={this.state.rowsPerPage}
                                                            page={page}
                                                            SelectProps={{
                                                                native: true,
                                                            }}
                                                            onChangePage={this.handleChangePage}
                                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                            ActionsComponent={TablePaginationActionsWrapped}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

AdminSearch.propTypes = {
    classes: PropTypes.object.isRequired,
};

// @ts-ignore
export default withStyles(styles)(AdminSearch);