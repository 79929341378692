import React from "react";
import { SignIn } from "aws-amplify-react";
import { withStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
//import DeleteIcon from '@material-ui/icons/SettingsBackupRestore';

const styles = (theme: Theme) => ({
    root: {
        width: '100%',
        margin: 6 * 3,
        //overflowX: 'auto',
    },
    logoPre: {
        //marginLeft: 6,
        marginRight: 3,
        color: '#0077B4',
        fontWeight: 'bold',
        fontFamily: 'Calibri',
        fontStyle: 'italic',
        fontSize: 25,
    },
    logoPost: {
        margin: 0,
        color: '#C8D400',
        fontWeight: 'bold',
        fontFamily: 'Calibri',
        fontStyle: 'italic',
        fontSize: 34,
    },
    heading: {
        margin: 6,
    },
    container: {
        width: '95%',
        padding: 6 * 3,
        //background: 'lightblue',
    },
    formcontent: {
        width: '95%',
        padding: 6 * 3,
        overflowX: 'auto',
    },
    formControl: {
        margin: 6,
        minWidth: 300,
    },
    formControlWide: {
        margin: 6,
        minWidth: 400,
    },
    checkBox: {
        margin: 6,
    },
    textField: {
        marginRight: 6,
        width: 240,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    greenButton: {
        margin: 6,
        background: '#C8D400',
    },
    blueButton: {
        background: '#0077B4',
    },
});

class CustomSignIn extends SignIn {
    // @ts-ignore
    constructor(props) {
        super(props);
        this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    }

    // @ts-ignore
    showComponent(theme: Theme) {
        // @ts-ignore
        const { classes } = this.props;
        return (
            <Grid container spacing={2} alignItems="center" justify="center" >
                <Grid item xs={4}>
                    <Paper className={classes.root}>
                        <Grid container spacing={2} alignItems="center" justify="center" >
                            <Grid item xs={10}>
                                <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                    <FormGroup row>
                                        <TextField
                                            required
                                            InputLabelProps={{ required: false }}
                                            id="username"
                                            key="username"
                                            name="username"
                                            label="E-Mail"
                                            className={classes.textField}
                                            onChange={this.handleInputChange}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                        <TextField
                                            required
                                            InputLabelProps={{ required: false }}
                                            id="password"
                                            key="password"
                                            name="password"
                                            label="Passwort"
                                            type="password"
                                            className={classes.textField}
                                            onChange={this.handleInputChange}
                                            margin="normal"
                                            variant="outlined"
                                            onKeyPress={ (e) => {
                                                if (e.key === 'Enter') {
                                                    super.signIn(e);
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup row>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            className={classes.blueButton}
                                            onClick={(e) => super.signIn(e)}>Anmelden</Button>
                                    </FormGroup>
                                </form>
                            </Grid>
                        </Grid>
                    </Paper>
                    <div onClick={() => super.changeState("forgotPassword")} style={{cursor: 'pointer', fontSize: 'smaller', textDecoration:'none'}}>Passwort erstellen/zurücksetzen</div>
                </Grid>
            </Grid>
        );
    }
}

// @ts-ignore
export default withStyles(styles)(CustomSignIn);