import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import PropTypes from 'prop-types';
import { withStyles, Theme } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';

const styles = (theme: Theme) => ({
    root: {
        maxWidth: '100%',
        marginTop: 6 * 3,
        marginLeft: '0',
        marginRight: '0'
        //background: 'lightblue',
    },
    heading: {
        margin: 6,
    },
    tablePaper: {
        width: '100%',
        margin: 6,
        overflowX: 'auto',
    },
    link: {
        margin: 6,
        fontWeight: 'bold',
        fontFamily: 'Calibri',
        fontStyle: 'italic',
        fontSize: 20,
    },
    releaseIcon: {
        color: '#0077B4'
    }
});

interface IProps {
    classes: any
}

interface IState {
    apiVisible: boolean,
    usermanagementVisible: boolean,
    vertragsfirmenVisible: boolean,
    adminsucheVisible: boolean,
    emailBody: string
}

class Footer extends Component<IProps, IState> {

    static propTypes = {};

    constructor(props: IProps) {
        super(props);

        this.state = {
            apiVisible: false,
            usermanagementVisible: false,
            vertragsfirmenVisible: false,
            adminsucheVisible: false,
            emailBody: "\n"
                + "=== Bitte tragen Sie hier Ihr Anliegen ein. ==="
                + "\n"
                + "\nBevorzugter Kontakt für Rückmeldungen (E-Mail/Telefonnummer): "
                + "\n"
                + "\n"
                + "\nACHTUNG: Wenn Sie eine Ein- oder Ausbaumeldung über den angedachten Weg nicht einreichen konnten, füllen Sie bitte die unten stehenden Felder aus und hängen ein Bild des Zählers an diese Mail an. "
                + "\n"
                + "\nHINWEIS: Bitte erläutern Sie unten das aufgetretene Problem, damit wir die Möglichkeit haben, den Prozess weiter zu verbessern. Vielen Dank!"
                + "\n"
                + "\n"
                + "\n=== Daten für einen BaZO-Auftrag: ==="
                + "\n"
                + "\nAuftragsnummer:"
                + "\nAnschlussobjekt:"
                + "\nAOB-Adresse:"
                + "\nEin-/Ausbaudatum:"
                + "\nZählernummer:"
                + "\nZählerstand 1.8.0:"
                + "\nZählerstand 1.8.1:"
                + "\nZählerstand 1.8.2:"
                + "\nZählerstand 2.8.0:"
                + "\n"
                + "\nErläuterung des Problems:"
        }
    }

    async getGroups() {
        try {
            const user = await Auth.currentAuthenticatedUser();
            //console.log(user.signInUserSession.accessToken.payload["cognito:groups"][0]);
            return user.signInUserSession.accessToken.payload["cognito:groups"];
        } catch (e) {
            console.log('get user group failed', e);
            return null;
        }
    }

    componentDidMount() {
        this.getGroups().then(groups => {
            this.setState({
                apiVisible: groups && groups.includes("EWE-Admin"),
                usermanagementVisible: groups && groups.includes("EWE-Admin"),
                vertragsfirmenVisible: groups && groups.includes("EWE-Admin"),
                adminsucheVisible: groups && groups.includes("EWE-Admin")
            });
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container alignItems="center" justify="center" style={{ margin: '0', maxWidth: '100%', width: '100%' }} >
                <Grid item xs={12}>
                    <Paper className={classes.root}>
                        <Grid container style={{ margin: '0', maxWidth: '100%', width: '100%' }} spacing={2} alignItems="center" justify="center" >
                            {this.state.apiVisible ? <Link to={'/swagger'} className={classes.link}>API</Link> : null}
                            <a href={"/impressum.html"} target="_blank" rel="noopener noreferrer" className={classes.link}>IMPRESSUM</a>
                            <a href={"/datenschutz.html"} target="_blank" rel="noopener noreferrer" className={classes.link}>DATENSCHUTZ</a>
                            {this.state.usermanagementVisible ? <Link to={'/users'} className={classes.link}>USERMANAGEMENT</Link> : null}
                            {this.state.vertragsfirmenVisible ? <Link to={'/vertragsfirma'} className={classes.link}>VERTRAGSFIRMEN</Link> : null}
                            <Link to={'/info/changelog'} className={classes.link}><NewReleasesIcon className={classes.releaseIcon} /></Link>
                            <a href={`mailto:Geraetewechsel@ewe-netz.de?subject=${encodeURIComponent("Problem bei Nutzung von BaZO") || ''}&body=${encodeURIComponent(this.state.emailBody) || ''}`} rel="noopener noreferrer" className={classes.link}>PROBLEM MELDEN</a>
                            {this.state.adminsucheVisible ? <Link to={'/adminsearch'} className={classes.link}>ADMINSUCHE</Link> : null}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
};

// @ts-ignore
export default withStyles(styles)(Footer);