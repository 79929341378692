import React from 'react';
import { Link } from 'react-router-dom'
import Button, { ButtonProps } from '@material-ui/core/Button';
import HistoryIcon from '@material-ui/icons/History';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import Hidden from '@material-ui/core/Hidden';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { LocationDescriptor } from 'history';


interface IBaustrommeldung {
    id: string,
    typ: string,
    auftragsnummer: string,
    aob: string,
    aobAdresse: string,
    status: string,
    searchParam: string
}

interface ButtonLinkProps extends ButtonProps {
    to: LocationDescriptor
}

interface TableCellLinkProps extends ButtonProps {
    classes: any,
    to: LocationDescriptor
}

const ButtonLink = ({ to, ...props }: ButtonLinkProps) => {
    // @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877
    return <Button component={Link} to={to} {...props} />;
};

const TableCellLink = ({ to, classes, ...props }: TableCellLinkProps) => {
    // @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877
    const inputRef = React.useRef(null);
    return (
        <TableCell style={{ cursor: 'pointer' }}
            className={classes.tableCell}
            onClick={() => {
                if (inputRef) {
                    // @ts-ignore
                    inputRef.current.click();
                }
            }
            }>
            <Link ref={inputRef} to={to}>
                <span style={{ display: 'block', textDecoration: 'none', color: 'black' }} {...props} />
            </Link>
        </TableCell>
    );
};

interface IBRProps {
    classes: any,
    baustrommeldung: IBaustrommeldung,
    filter: string,
    page: number,
    showAllFMO: boolean
}

interface IBRState {

}

const translateStatus = (status: string) => {
    if (status === "In Anlage durch Installateur") {
        return "Zwischengespeichert";
    }
    if (status === "Abgeschickt an EWE") {
        return "Eingereicht";
    }
    return status;
};

class BaustrommeldungRow extends React.Component<IBRProps, IBRState> {
    render() {
        const { classes } = this.props;
        const baustrommeldung = this.props.baustrommeldung;
        const filter = this.props.filter;
        const page = this.props.page;
        const showAllFMO = this.props.showAllFMO;
        const linkTo = baustrommeldung.typ === 'Zählereinbau' ? `/zaehlerEinbauFormOpen/${baustrommeldung.id}` : `/zaehlerAusbauFormOpen/${baustrommeldung.id}`;
        const BaustromIcon = (props: any) => {
            let returnVal = <HistoryIcon style={{ color: 'orange' }} fontSize="small" className={classes.rightIcon} />;
            switch (props.status) {
                case "Abgeschickt an EWE":
                    returnVal = <MailOutlineIcon style={{ color: 'darkblue' }} fontSize="small" className={classes.rightIcon} />;
                    break;
                case "Angenommen von EWE":
                    returnVal = <CallSplitIcon style={{ color: 'darkblue' }} fontSize="small" className={classes.rightIcon} />;
                    break;
                case "Abgeschlossen durch EWE":
                    returnVal = <CheckIcon style={{ color: 'green' }} fontSize="small" className={classes.rightIcon} />;
                    break;
                case "Verarbeitung nicht erfolgreich":
                    returnVal = <CloseIcon style={{ color: 'red' }} fontSize="small" className={classes.rightIcon} />;
                    break;
                case "Zur Bearbeitung an BZM":
                    returnVal = <CloseIcon style={{ color: 'red' }} fontSize="small" className={classes.rightIcon} />;
                    break;
                case "Vorprozess erfolgreich bearbeitet":
                    returnVal = <DoubleArrowIcon style={{ color: 'darkblue' }} fontSize="small" className={classes.rightIcon} />;
                    break;
            }
            return returnVal;
        };

        return (
            <TableRow
                key={baustrommeldung.id}
            >
                <TableCellLink to={{
                    pathname: linkTo,
                    state: {
                        filter: filter,
                        page: page,
                        showAllFMO: showAllFMO
                    }
                }} classes={classes}>
                    {
                        baustrommeldung.typ === 'Zählereinbau' ?
                            <CallMadeIcon style={{ color: 'green' }} fontSize="small" className={classes.rightIcon} /> :
                            <CallReceivedIcon color="primary" fontSize="small" className={classes.rightIcon} />
                    }
                    <Hidden smDown>&nbsp;{baustrommeldung.typ}</Hidden>
                </TableCellLink>
                <Hidden smDown>
                    <TableCellLink to={{
                        pathname: linkTo,
                        state: {
                            filter: filter,
                            page: page,
                            showAllFMO: showAllFMO
                        }
                    }} classes={classes}>{baustrommeldung.aob || ""}</TableCellLink>
                </Hidden>
                <TableCellLink to={{
                    pathname: linkTo,
                    state: {
                        filter: filter,
                        page: page,
                        showAllFMO: showAllFMO
                    }
                }} classes={classes}>{baustrommeldung.auftragsnummer}</TableCellLink>
                <TableCellLink to={{
                    pathname: linkTo,
                    state: {
                        filter: filter,
                        page: page,
                        showAllFMO: showAllFMO
                    }
                }} classes={classes}>{baustrommeldung.aobAdresse}</TableCellLink>
                <TableCell align="left" className={classes.tableCell}>
                    {
                        baustrommeldung.typ === 'Zählereinbau' ?
                            <ButtonLink variant="outlined" to={{
                                pathname: `/stateHistory/${baustrommeldung.id}`,
                                state: {
                                    filter: filter,
                                    page: page,
                                    showAllFMO: showAllFMO
                                }
                            }} className={classes.statusLabel}>
                                <BaustromIcon status={baustrommeldung.status} />&nbsp;&nbsp;
                                {translateStatus(baustrommeldung.status)}
                            </ButtonLink>
                            :
                            <ButtonLink variant="outlined" to={{
                                pathname: `/stateHistoryZaehlerausbau/${baustrommeldung.id}`,
                                state: {
                                    filter: filter,
                                    page: page,
                                    showAllFMO: showAllFMO
                                }
                            }} className={classes.statusLabel}>
                                <BaustromIcon status={baustrommeldung.status} />&nbsp;&nbsp;
                                {translateStatus(baustrommeldung.status)}
                            </ButtonLink>
                    }
                </TableCell>
            </TableRow>
        );
    }
}


export default BaustrommeldungRow;