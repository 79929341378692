// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.4.1 on 2023-11-29T19:47:25.026Z

const awsmobile = {
    aws_cloud_logic_custom: [
        {
            endpoint: 'https://1volmprf2k.execute-api.eu-central-1.amazonaws.com/prod',
            name: 'ApiGatewayRestApi',
            region: 'eu-central-1'
        }
    ],
    aws_cognito_identity_pool_id: 'eu-central-1:56564377-87d6-4386-9636-c93cc76d1132',
    aws_cognito_region: 'eu-central-1',
    aws_project_region: 'eu-central-1',
    aws_user_pools_id: 'eu-central-1_5KlRjHJom',
    aws_user_pools_web_client_id: '4i96b65u22n2a4o9m5gs0s40db'
};

export default awsmobile;
