import React, { Component } from 'react';
import { History, Location } from 'history';
import { API, Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { withStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BaustrommeldungTable from './BaustrommeldungTable';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';


const styles = (theme: Theme) => ({
    root: {
        maxWidth: '100%',
        //margin: 6,
        'margin-top': 6 * 3,
        //background: 'lightblue',
    },
    logoPre: {
        marginLeft: 6,
        marginRight: 3,
        color: '#0077B4',
        fontWeight: 'bold',
        fontFamily: 'Calibri',
        fontStyle: 'italic',
        fontSize: 25,
    },
    logoPost: {
        margin: 0,
        color: '#C8D400',
        fontWeight: 'bold',
        fontFamily: 'Calibri',
        fontStyle: 'italic',
        fontSize: 34,
    },
    heading: {
        margin: 6,
    },
    tablePaper: {
        //width: '98%',
        maxWidth: '100%',
        margin: 6,
        overflowX: 'auto',
    },
    table: {
        minWidth: 300,
    },
    greenButton: {
        margin: '1%',
        background: '#C8D400',
        width: '95%',
        textTransform: 'none',
        border: 'none'
    },
    blueButton: {
        margin: 6,
        background: '#0077B4'
    },
    blueButtonWhiteLabel: {
        margin: '1%',
        width: '95%',
        background: '#0077B4',
        color: '#ffffff',
        textTransform: 'none',
        border: 'none',
    },
    statusLabel: {
        background: 'transparent',
        color: 'black',
        border: 'none',
        outline: 'none',
        textTransform: 'none',
        paddingLeft: '0',
        margin: '0',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start'
    },
    tableCell: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 6,
        paddingRight: 6,
    },
    tableCellStatus: {
        width: '50%'
    },
    leftIcon: {
        marginRight: 6,
    },
    rightIcon: {
        marginLeft: 6,
    },
    iconSmall: {
        fontSize: 20,
    },
    toggledOnIcon: {
        color: '#ebd300',
        //display: 'block',
        //marginLeft: 'auto',
        //marginRight: 'auto',
    },
    toggledOffIcon: {
        color: '#eb0000',
        //display: 'block',
        //marginLeft: 'auto',
        //marginRight: 'auto',
    },
    spinner: {
        margin: 100,
    }
});

interface IBaustrommeldung {
    id: string,
    typ: string,
    auftragsnummer: string,
    aob: string,
    aobAdresse: string,
    status: string,
    searchParam: string
}

interface IOverviewProps {
    history: History,
    location: Location,
    classes: any
}

interface IOverviewState {
    baustrommeldungen: IBaustrommeldung[],
    benutzer: string,
    gruppe: string,
    addButtonVisible: boolean,
    isLoading: boolean
}

class Overview extends Component<IOverviewProps, IOverviewState> {

    static propTypes = {};

    constructor(props: IOverviewProps) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.reloadData = this.reloadData.bind(this);

        this.state = {
            baustrommeldungen: [],
            benutzer: '',
            gruppe: '',
            addButtonVisible: true,
            isLoading: true
        }
    }

    componentDidMount() {
        this.getUserId().then(id => this.setState({ benutzer: id }));
        this.getGroup().then(group => this.setState({ gruppe: group }));
        this.getData(false).then(data => this.setState({ 
            baustrommeldungen: data,
            isLoading: false
        }));
    }

    async reloadData(showAllFmo: boolean) {
        this.setState({isLoading: true});
        this.getData(showAllFmo).then(data => this.setState({ 
            baustrommeldungen: data,
            isLoading: false
        }));
    }

    async getData(loadAllData: boolean) {
        const user = await Auth.currentAuthenticatedUser();
        const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
        const group = (groups && groups[0]) ? groups[0] : "";
        // @ts-ignore
        const jwt = (await Auth.currentSession()).idToken.jwtToken;
        let endpoint = '/baustrom/current';
        if (loadAllData) {
            endpoint = '/baustrom'
        }

        //console.log(jwt);

        const data = await API.get('ApiGatewayRestApi', endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            }
        });
        //if (group === "verantwortliche_Fachkraft" || group === "Sachbearbeiter") {
        //return data.filter(row => row['benutzer'] === userId && row['auftragsstatus'] === 'In Anlage durch Installateur');
        //    this.setState({ addButtonVisible: true });
        //} else {
        //    this.setState({ addButtonVisible: false });
        //}
        // Suchstring hinzufügen
        data.forEach((meldung: any) => {
            //console.log(meldung);
            var searchParam =
                meldung.company
                + meldung.auftragsnummer
                + meldung.id
                + meldung.monteur
                + meldung.aob
                + meldung.status
                + meldung.typ
                + meldung.zaehlernummer
                + meldung.aobAdresse;
            meldung['searchParam'] = searchParam.toLowerCase();
        });
        //if (group === "EWE-Mitarbeiter" || group === "EWE-Admin") {
        if (group === "EWE-Admin") {
            this.setState({ addButtonVisible: true });
        }
        return data;
    }

    async getUserId() {
        try {
            const user = await Auth.currentAuthenticatedUser();
            return user.attributes.sub;
        } catch (e) {
            console.log('get user id failed', e);
            return null;
        }
    }

    async getGroup() {
        try {
            const user = await Auth.currentAuthenticatedUser();
            return user.signInUserSession.accessToken.payload["cognito:groups"][0];
        } catch (e) {
            console.log('get user group failed', e);
            return null;
        }
    }

    handleSubmit(event: any) {
        event.preventDefault();
        this.props.history.push('/zaehlerEinbauFormNew');
    }

    render() {
        const { classes } = this.props;

        let filter = '';
        // @ts-ignore
        if (typeof this.props.location !== 'undefined' && typeof this.props.location !== 'undefined' && typeof this.props.location.state !== 'undefined' && typeof this.props.location.state.filter !== 'undefined') {
            // @ts-ignore
            filter = this.props.location.state.filter;
        }
        let page = 0;
        // @ts-ignore
        if (typeof this.props.location !== 'undefined' && typeof this.props.location.state !== 'undefined' && typeof this.props.location.state.page !== 'undefined') {
            // @ts-ignore
            page = this.props.location.state.page;
        }
        let showAllFMO = false;
        // @ts-ignore
        if (typeof this.props.location !== 'undefined' && typeof this.props.location.state !== 'undefined' && typeof this.props.location.state.showAllFMO !== 'undefined') {
            // @ts-ignore
            showAllFMO = this.props.location.state.showAllFMO;
        }

        //console.log('filterBack: ' + filter);
        //console.log('pageBack: ' +  page);
        //console.log('showAllFMOBack: ' +  showAllFMO);

        return (
            <Grid container spacing={2} style={{ maxWidth: '100%' }} alignItems="center" justify="center" >
                <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center" justify="center" >
                        {/*
                        <Grid item xs={12}>
                            <form className="pure-form pure-form-aligned" onSubmit={this.handleSubmit}>
                                <FormGroup row>
                                    {this.state.addButtonVisible ?
                                        <Button type="submit" variant="contained" color="primary" className={classes.blueButton}>
                                            Zählereinbaurückmeldung erfassen
                                            <AddIcon className={classes.rightIcon} />
                                        </Button> : null}
                                </FormGroup>

                            </form>
                        </Grid>*/
                        }
                        <Grid item xs={12} lg={8}>
                            <BaustrommeldungTable baustrommeldungen={this.state.baustrommeldungen} reloadData={this.reloadData} isLoading={this.state.isLoading} addButtonVisible={this.state.addButtonVisible} gruppe={this.state.gruppe} filter={filter} page={page} showAllFMO={showAllFMO} classes={classes} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

Overview.propTypes = {
    classes: PropTypes.object.isRequired,
};

// @ts-ignore
export default withStyles(styles)(Overview);