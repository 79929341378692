import React from 'react';
import { Greetings } from 'aws-amplify-react';
import { NavBar, Nav, NavRight } from 'aws-amplify-react/lib/Amplify-UI/Amplify-UI-Components-React';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import EvStationIcon from '@material-ui/icons/EvStation';
import { withStyles, Theme } from '@material-ui/core/styles';
import config from '../../config';

const styles = (theme: Theme) => ({
    logoPre: {
        marginLeft: 6,
        marginRight: 3,
        color: '#0077B4',
        fontWeight: 'bold',
        fontFamily: 'Calibri',
        fontStyle: 'italic',
        fontSize: 25,
    },
    logoPost: {
        margin: 0,
        color: '#C8D400',
        fontWeight: 'bold',
        fontFamily: 'Calibri',
        fontStyle: 'italic',
        fontSize: 34,
    },
});

class MyGreetings extends Greetings {

    // @ts-ignore
    render() {
        // @ts-ignore
        const { classes } = this.props;

        const authState = this.props.authState || this.state.authState;
        const signedIn = (authState === 'signedIn');

        const theme = this.props.theme;
        const greeting = signedIn ? this.userGreetings(theme) : this.noUserGreetings(theme);
        if (!greeting) { return null; }

        return (
            <NavBar theme={theme}>
                <Nav theme={theme} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={10}>
                            <a href="/">
                                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <EvStationIcon />
                                        <Typography className={classes.logoPre} style={{ lineHeight: '1' }}>
                                            &nbsp;Baustrom-Zählerstände-Online {config.display}
                                        </Typography>
                                    </div>
                                </div>
                            </a>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <NavRight theme={theme}>
                                {greeting}
                                <Typography className={classes.logoPre} display="inline">
                                    EWE
                                </Typography>
                                <Typography className={classes.logoPost} display="inline">
                                    netz
                                </Typography>
                            </NavRight>
                        </Grid>
                    </Grid>
                </Nav>
            </NavBar>
        );
    }
}

// @ts-ignore
export default withStyles(styles)(MyGreetings);