import React, { lazy, Suspense } from 'react';
import { Authenticator, ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignUp, VerifyContact } from 'aws-amplify-react';
import { I18n } from 'aws-amplify';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './../styles/App.css';
import Overview from './zaehlermeldung/Overview';
import CustomSignIn from "../components/layout/CustomSignIn";
import MyGreetings from "../components/layout/MyGreetings";
import MyAmplifyTheme from "./../styles/MyAmplifyTheme";
import Footer from '../components/layout/Footer';
import AdminSearch from './zaehlermeldung/suche/AdminSearch';
const ZaehlerEinbauForm = lazy(() => { return import('./zaehlermeldung/einbau/ZaehlerEinbauForm'); });
const ZaehlerEinbauCreate = lazy(() => { return import('./zaehlermeldung/einbau/ZaehlerEinbauCreate'); });
const ZaehlerAusbauCreate = lazy(() => { return import('./zaehlermeldung/ausbau/ZaehlerAusbauCreate'); });
const ZaehlerAusbauPrefilledCreate = lazy(() => { return import('./zaehlermeldung/ausbau/ZaehlerAusbauPrefilledCreate'); });
const ZaehlerAusbauPreSearch = lazy(() => { return import('./zaehlermeldung/ausbau/ZaehlerAusbauPreSearch'); });
const ZaehlerAusbauForm = lazy(() => { return import('./zaehlermeldung/ausbau/ZaehlerAusbauForm'); });
const UserList = lazy(() => { return import("./user/UserList"); });
const User = lazy(() => { return import("./user/User"); });
const UserCreate = lazy(() => { return import("./user/UserCreate"); });
const Vertragsfirmen = lazy(() => { return import("./vertragsfirma/Vertragsfirmen"); });
const Vertragsfirma = lazy(() => { return import("./vertragsfirma/Vertragsfirma"); });
const VertragsfirmaCreate = lazy(() => { return import("./vertragsfirma/VertragsfirmaCreate"); });
const StateHistory = lazy(() => { return import("./zaehlermeldung/statehistory/StateHistoryZaehlereinbau"); });
const StateHistoryZaehlerausbau = lazy(() => { return import("./zaehlermeldung/statehistory/StateHistoryZaehlerausbau"); });
const Changelog = lazy(() => { return import('./info/Changelog'); });
const Swagger = lazy(() => { return import('./Swagger'); });

class AppWithAuth extends React.Component {

    render() {
        const dict = {
            'de': {
                'Username': 'E-Mail (Username)',
                'Enter your username': 'E-Mail (Username) eingeben',
                'Send Code': 'Weiter',
                'Back to Sign In': 'Zurück',
                'Reset your password': "Passwort erstellen/zurücksetzen: Es wird Ihnen per E-Mail ein Bestätigungscode zugesendet.",
                'Code': 'Bestätigungscode aus der E-Mail',
                'New Password': 'Neues Passwort (>8 Stellen, Sonderz., Zahl, Großbuchstabe)'
            }
        };
        I18n.putVocabularies(dict)
        I18n.setLanguage('de');
        return (
            // @ts-ignore
            <Router history={this.props.history} location={this.props.location}>
                <Authenticator hideDefault={true} theme={MyAmplifyTheme}>
                    <MyGreetings
                        // @ts-ignore
                        inGreeting={
                            (username: string) => <small>Angemeldet als {username} </small>
                        }
                        outGreeting="Bitte loggen Sie sich ein..."
                    />
                    <CustomSignIn />
                    <SignUp />
                    <ConfirmSignIn />
                    <ConfirmSignUp />
                    <RequireNewPassword />
                    <ForgotPassword />
                    <VerifyContact />
                    <App />
                </Authenticator>
            </Router>
        );
    }
}

class App extends React.Component {

    render() {
        // @ts-ignore
        if (this.props.authState === "signedIn") {
            return (
                <React.Fragment>
                    <Suspense fallback={
                        <div className="h-100">
                            Loading...
                        </div>
                    }>
                        <Switch>
                            <Route exact path="/" component={Overview} />
                            <Route exact path="/zaehlerEinbauFormNew" component={ZaehlerEinbauCreate} />
                            <Route exact path="/zaehlerAusbauFormNew" component={ZaehlerAusbauCreate} />
                            <Route exact path="/zaehlerAusbauFormNewPrefilled" component={ZaehlerAusbauPrefilledCreate} />
                            <Route exact path="/ZaehlerAusbauPreSearch" component={ZaehlerAusbauPreSearch} />
                            <Route exact path="/zaehlerEinbauFormOpen/:id" component={ZaehlerEinbauForm} />
                            <Route exact path="/zaehlerAusbauFormOpen/:id" component={ZaehlerAusbauForm} />
                            <Route exact path="/stateHistory/:id" component={StateHistory} />
                            <Route exact path="/stateHistoryZaehlerausbau/:id" component={StateHistoryZaehlerausbau} />
                            <Route exact path="/users" component={UserList} />
                            <Route exact path="/user/create" component={UserCreate} />
                            <Route exact path="/user/:id" component={User} />
                            <Route exact path="/vertragsfirma" component={Vertragsfirmen} />
                            <Route exact path="/vertragsfirma/create" component={VertragsfirmaCreate} />
                            <Route exact path="/vertragsfirma/:id" component={Vertragsfirma} />
                            <Route exact path="/swagger" component={Swagger} />
                            <Route exact path="/info/changelog" component={Changelog} />
                            <Route exact path="/info/changelog/:content" component={Changelog} />
                            <Route exact path="/adminsearch" component={AdminSearch} />
                        </Switch>
                    </Suspense>
                    <Footer />
                </React.Fragment>
            );
        } else {
            return null;
        }
    }
}


export default AppWithAuth;
