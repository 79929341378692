import React from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';
import BaustrommeldungRow from './BaustrommeldungRow';
import TablePaginationActions from './TablePaginationActions';
import ZaehlermeldungList from './BaustrommeldungTable.presenter';


interface IBaustrommeldung {
    id: string,
    typ: string,
    auftragsnummer: string,
    aob: string,
    aobAdresse: string,
    status: string,
    searchParam: string
}

interface IBTProps {
    classes: any,
    baustrommeldungen: IBaustrommeldung[],
    reloadData(showAllFMO: boolean): void,
    isLoading: boolean,
    addButtonVisible: boolean,
    gruppe: string,
    filter: string,
    page: number,
    showAllFMO: boolean
}

interface IBTState {
    page: number,
    rowsPerPage: number,
    showAllFMO: boolean,
    filter: string
}


const actionsStyles = (theme: Theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: 6 * 2.5,
    },
});

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
    TablePaginationActions,
);


class BaustrommeldungTable extends React.Component<IBTProps, IBTState> {

    constructor(props: IBTProps) {
        super(props);

        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.toggleViewClosedFMOButtonClick = this.toggleViewClosedFMOButtonClick.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);

        this.state = {
            page: props.page,
            rowsPerPage: 10,
            showAllFMO: props.showAllFMO,
            filter: props.filter
        }
    }

    handleChangePage = (event: any, page: number) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event: any) => {
        this.setState({ page: 0, rowsPerPage: Number(event.target.value) });
    };

    toggleViewClosedFMOButtonClick = (event: any) => {
        this.setState({ showAllFMO: !this.state.showAllFMO });
        this.props.reloadData(!this.state.showAllFMO);
    };

    handleChangeFilter(event: any) {
        this.setState({
            filter: event.target.value,
            page: 0
        });
    }

    render() {
        const { classes, isLoading } = this.props;
        const { rowsPerPage, page } = this.state;

        //console.log('filter: ' + this.state.filter);
        //console.log('page: ' + this.state.page);
        //console.log('showAllFMO: ' + this.state.showAllFMO);

        let addButtonVisible = this.props.addButtonVisible;

        // @ts-ignore
        const rows: any[] = [];

        let baustrommeldungen = this.props.baustrommeldungen;
        //fertigmeldungen.sort(function(a, b){return b.createdAt - a.createdAt});
        if (!this.state.showAllFMO) {
            baustrommeldungen = baustrommeldungen.filter(row => {
                return (this.props.gruppe === "verantwortliche_Fachkraft" || this.props.gruppe === "Sachbearbeiter") ? row['status'] === 'Zur Bearbeitung bei VF' : row['status'] !== 'Abgeschlossen durch EWE';
            });
        }
        if (this.state.filter && this.state.filter !== '') {
            baustrommeldungen = baustrommeldungen.filter(row => {
                return row['searchParam'].includes(this.state.filter.toLowerCase());
            });
        }

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, baustrommeldungen.length - page * rowsPerPage);

        baustrommeldungen.forEach((baustrommeldung) => {
            rows.push(
                <BaustrommeldungRow
                    baustrommeldung={baustrommeldung}
                    key={baustrommeldung.id}
                    filter={this.state.filter}
                    page={this.state.page}
                    showAllFMO={this.state.showAllFMO}
                    classes={classes} />
            );
        });



        return (
            <ZaehlermeldungList
                classes={classes}
                baustrommeldungen={baustrommeldungen}
                showAllFMO={this.state.showAllFMO}
                rows={rows}
                emptyRows={emptyRows}
                rowsPerPage={rowsPerPage}
                filter={this.state.filter}
                page={this.state.page}
                addButtonVisible={addButtonVisible}
                toggleViewClosedFMOButtonClick={this.toggleViewClosedFMOButtonClick}
                handleChangeFilter={this.handleChangeFilter}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                TablePaginationActionsWrapped={TablePaginationActionsWrapped}
                isLoading={isLoading} />
        );
    }
}


export default BaustrommeldungTable;