import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LocationDescriptor } from 'history';


interface ButtonLinkProps extends ButtonProps {
    to: LocationDescriptor
}

interface IBaustrommeldung {
    id: string,
    typ: string,
    auftragsnummer: string,
    aob: string,
    status: string,
    searchParam: string
}

interface IZaehlermeldungList {
    classes: any,
    baustrommeldungen: IBaustrommeldung[],
    showAllFMO: boolean,
    rows: any[],
    emptyRows: number,
    rowsPerPage: number,
    filter: string,
    page: number,
    addButtonVisible: boolean,
    toggleViewClosedFMOButtonClick(event: any): void,
    handleChangeFilter(event: any): void,
    handleChangePage(event: any, page: number): void,
    handleChangeRowsPerPage(event: any): void,
    TablePaginationActionsWrapped: any,
    isLoading: boolean
}

const ButtonLink = ({ to, ...props }: ButtonLinkProps) => {
    // @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877
    return <Button component={Link} to={to} {...props} />;
};

const ZaehlermeldungList = ({ classes, baustrommeldungen, showAllFMO, rows, emptyRows, rowsPerPage, filter, page, addButtonVisible, toggleViewClosedFMOButtonClick, handleChangeFilter, handleChangePage, handleChangeRowsPerPage, TablePaginationActionsWrapped, isLoading }: IZaehlermeldungList) => (

    <Paper className={classes.tablePaper} style={{ maxWidth: '100%' }}>
        <Typography variant="h5" component="h3" className={classes.heading}>
            Baustromrückmeldungen ({baustrommeldungen.length})
        </Typography>
        <Grid container style={{ maxWidth: '100%' }}>
            <Grid item xs={12} md={6}>
                <Tooltip title={
                    <span style={{ fontSize: 'larger' }}>
                        {showAllFMO ?
                            "Eingereichte und geschlossene Baustrommeldungen ausblenden"
                            : "Eingereichte und geschlossene Baustrommeldungen anzeigen"}
                    </span>
                }>
                    <IconButton
                        onClick={toggleViewClosedFMOButtonClick}
                        aria-label="Eingereichte und geschlossene Baustrommeldungen anzeigen"
                    >
                        {showAllFMO ?
                            <ToggleOnIcon fontSize="large" className={classes.toggledOnIcon} />
                            : <ToggleOffIcon fontSize="large" className={classes.toggledOffIcon} />}
                    </IconButton>
                </Tooltip>
                <TextField id="standard-basic"
                    inputProps={{
                        autoComplete: 'off'
                    }}
                    label="Suche"
                    onChange={(e) => {
                        // @ts-ignore
                        handleChangeFilter(e);
                    }}
                    value={filter}
                    style={{ marginLeft: '2em' }}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                {/*addButtonVisible ?
                    <ButtonLink to={`/ZaehlerEinbauFormNew/`} className={classes.greenButton}>
                        <CallMadeIcon style={{ color: 'black' }} fontSize="small" className={classes.rightIcon} />
                        &nbsp;Zählereinbau melden
                    </ButtonLink> : null
                */}
            </Grid>
            <Grid item xs={12} md={3}>
                {addButtonVisible ?
                    <ButtonLink to={`/ZaehlerAusbauPreSearch/`} className={classes.blueButtonWhiteLabel}>
                        <CallReceivedIcon style={{ color: 'white' }} fontSize="small" className={classes.rightIcon} />
                        &nbsp;Zählerausbau melden
                    </ButtonLink> : null
                }
            </Grid>
        </Grid>
        <div className={classes.tableWrapper}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left" className={classes.tableCell}>Art</TableCell>
                        <Hidden smDown>
                            <TableCell align="left" className={classes.tableCell}>AOB</TableCell>
                        </Hidden>
                        <TableCell align="left" className={classes.tableCell}>Auftragsnr.</TableCell>
                        <TableCell align="left" className={classes.tableCell}>AOB Adresse</TableCell>
                        <TableCell align="left" className={classes.tableCellStatus}>Status</TableCell>
                    </TableRow>
                </TableHead>
                {isLoading ? <div className={classes.spinner}><CircularProgress /></div> :
                    <TableBody>
                        {
                            // @ts-ignore
                            rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        }
                        {
                            emptyRows > 0 && (
                                <TableRow style={{ height: 48 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )
                        }
                    </TableBody>}
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            labelRowsPerPage="Zeilen pro Seite"
                            colSpan={6}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActionsWrapped}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </div>
    </Paper>

);


export default ZaehlermeldungList;