import awsexports from './aws-exports';

const configSetting = {
  prod: {
    stage: "prod",
    display: ""
  },
  stage: {
    stage: "stage",
    display: " #STAGE"
  },
  dev: {
    stage: "dev",
    display: " #DEV",
  }
};


let stage = awsexports.aws_cloud_logic_custom[0].endpoint.split("com/")[1];

if (!stage || stage === 'undefined') {
  stage = 'dev';
}

let config = configSetting[stage];

export default {
  ...config
};
