import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify'
import AppWithAuth from './pages/App';
import GoogleTagManager from './components/statistics/GoogleTagManager';

import awsexports from './aws-exports';
import * as serviceWorker from './serviceWorker';

//import './App.css';

Amplify.configure(awsexports);

//GTM-NPKZFSB
ReactDOM.render(
    <div>
      <GoogleTagManager gtmId='GTM-585GVZ7' />
      <AppWithAuth />
    </div>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
